import { CommonModule, JsonPipe } from '@angular/common';
import { Component, computed, effect, inject, OnInit, Signal, signal, untracked, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DsCheckboxModule, DsFormFieldModule, DsToastService, DsToggleButtonModule } from '@bmw-ds/components';
import { StreamsComponent } from '../streams/streams.component';
import { VehiclesComponent } from "../vehicles/vehicles.component";
import { VehiclesListComponent } from "../vehicles-list/vehicles-list.component";
import { AppointmentsListComponent } from '../appointments-list/appointments-list.component';
import { SiteConfigurationService, Lane } from '@bmw-spp/bmw-spp-frontend-common';
import debug from 'debug';
class RoadwaysViewSelection {
  streams: boolean = true;
  list: boolean = true;
  appointments: boolean = true;
}

@Component({
  selector: 'app-roadways',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    JsonPipe,
    DsToggleButtonModule,
    DsCheckboxModule,
    DsFormFieldModule,
    StreamsComponent,
    VehiclesComponent,
    VehiclesListComponent,
    AppointmentsListComponent
  ],
  templateUrl: './roadways.component.html',
  styleUrl: './roadways.component.scss'
})
export class RoadwaysComponent implements OnInit {
  public siteConfigurationService = inject(SiteConfigurationService); 
  
  private logDebug = debug('app:RoadwaysComponent:log');

  constructor(
    private toastService: DsToastService,) {

    this.logDebug.bind(console.log);

    effect(() => {
      const streams = this.siteConfigurationService.data();
      this.selectedStreams.set([]);
      this.logDebug('updating selected streams after config change');

      if (Array.isArray(streams)) {
        for (let i = 0; i < streams.length; i++) {
          untracked(()=>{
            this.toggleSelectedStream(streams[i]);
          })
        }
      }
    }, {
      allowSignalWrites: true
    })

  }

  public optionalText: string = ' ';

  // View selection
  public viewSelection: WritableSignal<RoadwaysViewSelection> = signal(new RoadwaysViewSelection());
  public colClass: Signal<string> = computed(() => {
    const currentViewSelection = this.viewSelection();
    // Check how many buttons are selected
    let count: number = 1; // Always at least one selected
    if (currentViewSelection.appointments) {
      count++;
    }
    if (currentViewSelection.list) {
      count++;
    }

    return `col-${12 / count}`;
  });
  public streamsColClass: Signal<string> = computed(() => {
    if (this.viewSelection().list && this.viewSelection().appointments) {
      return 'col-3';
    }

    if (this.viewSelection().list && !this.viewSelection().appointments) {
      return 'col-3';
    }

    if (this.viewSelection().appointments && !this.viewSelection().list) {
      return 'col-9';
    }

    if (!this.viewSelection().appointments && !this.viewSelection().list) {
      return 'col-12';
    }

    return 'col-12';
  });
  public appointmentColClass: Signal<string> = computed(() => {
    if (this.viewSelection().streams || this.viewSelection().list) {
      return 'col-3';
    }

    return 'col-12';
  });
  public listColClass: Signal<string> = computed(() => {
    if (this.viewSelection().streams && this.viewSelection().appointments) {
      return 'col-6';
    }

    if (this.viewSelection().streams && !this.viewSelection().appointments) {
      return 'col-9';
    }

    if (this.viewSelection().appointments && !this.viewSelection().streams) {
      return 'col-9';
    }

    if (!this.viewSelection().appointments && !this.viewSelection().list) {
      return 'col-12';
    }

    return 'col-12';
  });

  public selectedStreams = signal<Lane[]>([]);

  async ngOnInit() {
    try {
      await this.siteConfigurationService.loadConfig();
    } catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Laden. Bitte versuchen Sie es später erneut.'
      });
    }
  }

  public handleStreamsSelection(event: boolean) {
    this.viewSelection.set({
      ...this.viewSelection(),
      streams: event
    });
  }

  public handleListSelection(event: boolean) {
    this.viewSelection.set({
      ...this.viewSelection(),
      list: event
    });
  }

  public handleAppointmentsSelection(event: boolean) {
    this.viewSelection.set({
      ...this.viewSelection(),
      appointments: event
    });
  }

  public toggleSelectedStream(lane: Lane) {
    let selected = this.selectedStreams();
    if (selected.includes(lane)) {
      selected = selected.filter(value => value !== lane);
    } else {
      selected.push(lane);
    }
    this.selectedStreams.set([...selected]);
  }
}
