<ds-header
  [navBarRef]="navBar"
  appName="{{ title }}"
  [environmentTag]="environmentTagConfig"
  [userInfo]="userInfo"
  [menuTemplate]="testOption"
>
  <ng-template #testOption>
    <ds-list-item icon="user" [labelTemplate]="tenantSelection"
      >Mandant</ds-list-item
    >
    <ng-template #tenantSelection>
      <ds-form-field>
        <label ds-label for="tenants">Mandanten</label>
        <ds-select
          id="tenants"
          [value]="dsSelectDefaultValue()"
          [options]="tenantOptions"
          (optionSelected)="handleTenantSelection($event)"
        ></ds-select>
      </ds-form-field>
    </ng-template> </ng-template
></ds-header>
<div class="d-flex">
  <div class="nav-bar-wrapper position-sticky">
    <ds-navigation-bar
      class="nav-bar-wrapper position-sticky"
      #navBar
      [items]="navigationItems"
    ></ds-navigation-bar>
  </div>

  <div class="container-fluid py-6x">
    <router-outlet />
  </div>
</div>
