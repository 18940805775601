import { baseBackendEnvironment } from "@bmw-spp/bmw-spp-frontend-common";
import { DateService } from "../app/shared/date/date.service";
import { LocationProviderBackendService } from "../app/shared/location-provider/location-provider.backend.service";
import { LocationProviderService } from "../app/shared/location-provider/location-provider.service";
import { StatisticsService } from "../app/shared/statistics/statistics.service";
import { LaneMockService } from "../app/shared/streams/lane.mock.service";
import { LaneService } from "../app/shared/streams/lane.service";
import { Environment } from "./ienvironment";

// workaround for CANCOM environment.
export const environment: Environment = {
  ...baseBackendEnvironment,
  environmentName: 'Dev',
  apiUrl: 'https://spp-backend.c2-cloud.de/grt/',
  wsUrl: 'wss://spp-backend.c2-cloud.de/grt/ws/',

  providers: [
    ...baseBackendEnvironment.providers,
    { provide: DateService, useClass: DateService },
    { provide: LaneService, useClass: LaneMockService },
    { provide: LocationProviderService, useClass: LocationProviderBackendService },
    { provide: StatisticsService, useClass: StatisticsService },

  ],
  authEnabled: true,
  clientId: "grt-frontend",
  issuer: "https://keycloakspp.c2-cloud.de/realms/spp",
  secureRouteRootUrl: "https://spp-backend.c2-cloud.de/grt/",
  redirectURL: window.location.origin,
};
