import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, TitleStrategy } from '@angular/router';

import { routes } from './app.routes';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import {
  DsImprintModule,
} from '@bmw-ds/components';
import { ENVIRONMENT, TenantIdInterceptor } from '@bmw-spp/bmw-spp-frontend-common';
import { authInterceptor, LogLevel, provideAuth } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { CustomTitleStrategy } from './custom-title.strategy';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([authInterceptor()])),
    provideAnimations(),
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
    BrowserAnimationsModule,
    provideAnimations(),
    importProvidersFrom(
      DsImprintModule.forRoot({
        phone: '5555555',
        electronicContact: 'change-me@bmwgroup.com'
      }),
    ),
    provideAuth({
      config: {
        authority: environment.issuer,
        redirectUrl: environment.redirectURL,
        postLogoutRedirectUri: environment.redirectURL,
        clientId: environment.clientId,
        scope: 'openid profile email address bmwids organization groups',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
        secureRoutes: [environment.secureRouteRootUrl],
        historyCleanupOff: true,
        ignoreNonceAfterRefresh: true,
        silentRenewUrl:          window.location.origin + '/assets/silent-check-sso.html',
      }
    }),

    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantIdInterceptor,
      multi: true,
    },
    { provide: ENVIRONMENT, useValue: environment },
    ...environment.providers,
  ],
};