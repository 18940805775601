<div ds-loading-area-container>
  <ds-tag
    *ngFor="let item of gridApi?.getFilterModel() | keyvalue"
    [isDismissable]="true"
    (dismiss)="dismissFilterForColumn(item)"
    tone="info"
    size="lg"
  >
    <span *ngIf="isDateFilterModel(item.value)">
      {{ getColumnHeader(item.key) }}:
      {{ getFormattedDate(item.value.dateFrom) }}
      {{ item.value.dateTo ? " - " + getFormattedDate(item.value.dateTo) : "" }}
    </span>
    <span *ngIf="!isDateFilterModel(item.value)">
      {{ getColumnHeader(item.key) }}: {{ item.value.values.join(", ") }}
    </span>
  </ds-tag>
  <button
    *ngIf="gridApi && (gridApi.getFilterModel() | json) !== '{}'"
    ds-button
    variant="ghost"
    type="button"
    (click)="resetAllFilters()"
  >
    Alle löschen
  </button>
  <ag-grid-angular
    class="ag-theme-density spp-grid"
    domLayout="normal"
    [rowData]="vehicles()"
    [columnDefs]="columns()"
    [gridOptions]="gridOptions()"
    [defaultColDef]="defaultColDef()"
    [suppressAggFuncInHeader]="true"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [statusBar]="statusBar"
    [sideBar]="sideBar"
    (gridReady)="onGridReady($event)"
    [isExternalFilterPresent]="isExternalFilterPresent"
    [doesExternalFilterPass]="doesExternalFilterPass"
    [autoSizeStrategy]="autoSizeStrategy"
    [paginationPageSize]="paginationPageSize"
    [paginationPageSizeSelector]="paginationPageSizeSelector"
    [pagination]="true"
    [localeText]="localeText"
    [getRowId]="getRowId"
    [getContextMenuItems]="getContextMenuItems"
  >
  </ag-grid-angular>
  <app-pairing-dialog
    *ngIf="
      showPairingDialog() &&
      selectedGridRow() !== null &&
      selectedGridRow()!.data &&
      selectedGridRow()!.data!.vehicle!.licensePlate
    "
    (dismissModalEvent)="closeModal()"
    (pairingResultEvent)="handlePairingResult()"
    [license_plate]="selectedGridRow()!.data!.vehicle!.licensePlate!"
    [pairing]="{
      guideNumber: selectedGridRow()!.data?.vehicle?.pairing?.guideNumber,
      reason: selectedGridRow()!.data?.vehicle?.visitReason,
      vin: selectedGridRow()?.data?.vehicle?.vin,
      customerIsWaiting: selectedGridRow()?.data?.customerIsWaiting,
      vehicleStayId: selectedGridRow()?.data?.id,
    }"
    [dialogRole]="pairingDialogRole()"
  >
  </app-pairing-dialog>
</div>
